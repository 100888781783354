import { locales, localeDefault, extractLocale, navigateLocale } from "../lang";
import { getCookie, setCookie } from "../../libraries/cookies";
import { init as initSentry, browserTracingIntegration, replayIntegration } from "@sentry/react";

const isProd = process.env.NODE_ENV === "production";

const redirectLocale = () => {
  const url = new URL(window.location.href);

  let { urlPathnameWithoutLocale, locale: extractionLocale } = extractLocale(url.pathname);
  if (urlPathnameWithoutLocale === "/") urlPathnameWithoutLocale = "";

  let fixedLang = getCookie("gamly-lang");

  //clear if invalid
  if (!locales.some((l) => l.code === fixedLang)) {
    fixedLang = undefined;
    setCookie("gamly-lang", "", { expires: new Date(0) });
  }

  if (fixedLang && extractionLocale !== fixedLang) {
    return navigateLocale(urlPathnameWithoutLocale || "/", fixedLang, { replace: true });
  }
  if (extractionLocale !== "en" || fixedLang) return;

  let preferredLang = navigator.language || navigator.userLanguage;
  preferredLang = preferredLang.split("-")[0];
  if (!locales.some((l) => l.code === preferredLang)) preferredLang = localeDefault.code;

  let finalLang;
  if (preferredLang && extractionLocale !== preferredLang) {
    finalLang = preferredLang;
  }

  if (finalLang) return navigateLocale(urlPathnameWithoutLocale || "/", finalLang, { replace: true });
};
redirectLocale();

initSentry({
  dsn: "https://79c9d9b175a63b8172c88b50fb183a87@o4508122381484032.ingest.de.sentry.io/4508122387316816",
  environment: isProd ? "production" : "development",
  integrations: [browserTracingIntegration(), replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: isProd ? 0.1 : 1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
